<template>
  <v-container id="news-container">
    <div id="posts-container">
      <page-header id="page-header" style="align-self: center" v-bind:style="{width: postWidth + 'px'}">{{ $t('main.menu_news') }}</page-header>

      <div class="post-item-container" v-for="post in posts" :key="post">
        <iframe class="elevation-2" v-if="postWidth > 0" v-bind:src="post" height="741" :width="postWidth" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      </div>
    </div>
  </v-container>
</template>

<script>

import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    PageHeader
  },
  mounted() {
    this.postWidth = Math.min(700, document.getElementById('posts-container')?.getBoundingClientRect().width);
  },
  data: function () {
    return {
      postWidth: 0,
      posts: [
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7010568641221935104',
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7005883046004445184',
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7001463046103560192',
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6998298743183704064',
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6996459997626818560',
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6990666902578552832',
        'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6988063558907371521',
      ]
    }
  },
}
</script>
<style scoped>
#news-container {
  display: flex;
  flex-direction: column;
}

#posts-container {
  display: grid;
  margin-top: 16px;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

#posts-container > #page-header {
  grid-column: 1 / -1;
}

.post-item-container {
  display: flex;
  justify-content: center;
}

#posts-container iframe {
  border-radius: 4px;
  background-color: #c1c1c1;
}
</style>
